import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDiscussion, IComment, IDiscussionPayload } from '../types';

type InitialState = {
  loading: boolean;
  discussions: IDiscussion[];
  error: string;
};

interface INewComment {
  parentId?: number;
  data: IComment;
}

const initialState: InitialState = {
  loading: false,
  discussions: [
    {
      id: 3,
      date: 1672576574000,
      user: {
        name: 'Bessie Cooper',
        avatar: 'https://www.godaddy.com/garage/wp-content/uploads/judith-kallos-BW-NEW-150x150.jpg',
      },
      text: 'I think for our second compaign we can try to target a different audience. How does it sound for you?',
      likes: 2,
      iLikedIt: false,
      replies: [
        {
          id: 5,
          date: 1672581014000,
          user: {
            name: 'Marvin McKinney',
            avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
          },
          text: 'Yes, that sounds good! I can think about this tomorrow. Then do we plan to start that compaign?',
          likes: 3,
          iLikedIt: true,
        },
        {
          id: 6,
          date: 1672581614000,
          user: {
            name: 'Bessie Cooper',
            avatar: 'https://www.godaddy.com/garage/wp-content/uploads/judith-kallos-BW-NEW-150x150.jpg',
          },
          text: 'We plan to run the compaign on Friday - as far as I know. Do you think you will get this done by Thursday @Marvin?',
          likes: 0,
          iLikedIt: false,
        },
      ],
    },
    {
      id: 2,
      date: 1672232414000,
      user: {
        name: 'Marvin McKinney',
        avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
      },
      text: 'The first compaign went smoothly. Please make sure to see all attachments with the results to understand the flow.',
      likes: 2,
      iLikedIt: false,
      replies: [],
    },
    {
      id: 1,
      date: 1671886814000,
      user: {
        name: 'Savannah Nguyen',
      },
      text: "We have just published the first campaign. Let's see the results in the 5 days and we will iterate on this.",
      likes: 50,
      iLikedIt: true,
      replies: [],
    },
  ],
  error: '',
};

const discussionsSlice = createSlice({
  name: 'discussions',
  initialState,
  reducers: {
    newComment: (state, action: PayloadAction<INewComment>) => {
      if (!action.payload.parentId) {
        const newDiscussions = {
          replies: [],
          ...action.payload.data,
        };
        state.discussions.unshift(newDiscussions);
      } else {
        const parentIndex = state.discussions.findIndex((el) => el.id === action.payload.parentId);
        state.discussions[parentIndex].replies.push(action.payload.data);
      }
    },
    like: (state, action: PayloadAction<IDiscussionPayload>) => {
      if (!action.payload.disscussionID) {
        const index = state.discussions.findIndex((el) => el.id === action.payload.commentID);
        state.discussions[index].iLikedIt ? state.discussions[index].likes-- : state.discussions[index].likes++;
        state.discussions[index].iLikedIt = !state.discussions[index].iLikedIt;
      } else {
        const parentIndex = state.discussions.findIndex((el) => el.id === action.payload.disscussionID);
        const replyIndex = state.discussions[parentIndex].replies.findIndex((el) => el.id === action.payload.commentID);
        state.discussions[parentIndex].replies[replyIndex].iLikedIt
          ? state.discussions[parentIndex].replies[replyIndex].likes--
          : state.discussions[parentIndex].replies[replyIndex].likes++;
        state.discussions[parentIndex].replies[replyIndex].iLikedIt =
          !state.discussions[parentIndex].replies[replyIndex].iLikedIt;
      }
    },
  },
});

export default discussionsSlice.reducer;
export const { newComment, like } = discussionsSlice.actions;
