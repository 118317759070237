import React, { FC } from 'react';
import './Comment.scss';
import { IComment, IDiscussion } from '../../types';
import Button from '../Button/Button';
import { useAppDispatch } from '../../store/hooks';
import { like } from '../../store/discussionsSlice';
import Avatar from './../Avatar/Avatar';
import moment from 'moment';

interface CommentProps {
  data: IDiscussion | IComment;
  parentId?: number;
  showHideReply?: any;
}

const Comment: FC<CommentProps> = ({ data, parentId, showHideReply }) => {
  const dispatch = useAppDispatch();
  const replyClicked = () => {
    showHideReply();
  };
  return (
    <div className={'Comment ' + (parentId ? 'isChild' : 'isParent')}>
      <Avatar url={data.user.avatar} name={data.user.name} />
      <div className="Comment__description">
        <h2 className="Comment__description__name">
          {data.user.name} <small className="Comment__description__date">{moment(data.date).fromNow()}</small>
        </h2>
        <p className="Comment__description__text">{data.text}</p>
        <div className="Comment__description__footer">
          <Button
            onClick={() => dispatch(like({ commentID: data.id, disscussionID: parentId }))}
            theme={data.iLikedIt ? 'themePrimary' : 'themeLight'}
            rounded={'roundedFull'}
          >
            {data.likes}
          </Button>
          {!parentId && (
            <Button onClick={replyClicked} theme={'themeLink'}>
              reply
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
