import React, { FC, useState } from 'react';
import './CreateComment.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { newComment } from '../../store/discussionsSlice';
import Avatar from './../Avatar/Avatar';
import moment from 'moment';

interface CreateCommentProps {
  parentId?: number;
  placeholder?: string;
}

const CreateComment: FC<CreateCommentProps> = ({ parentId, placeholder }) => {
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const createComment = (e: React.FormEvent) => {
    e.preventDefault();
    if (text.trim()) {
      const dateAndId = moment().valueOf();
      dispatch(
        newComment({
          parentId,
          data: {
            user,
            id: dateAndId,
            text,
            likes: 0,
            iLikedIt: false,
            date: dateAndId,
          },
        })
      );
      setText('');
      if (!parentId) window.scrollTo(0, 0);
    }
  };
  return (
    <form className="CreateComment" onSubmit={createComment}>
      <Avatar url={user.avatar} name={user.name} />
      <input
        value={text}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        className="CreateComment__input"
        name="comment"
      />
    </form>
  );
};

export default CreateComment;
