import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../types'

type InitialState = {
  loading: boolean
  user: IUser
  error: string
}

const initialState: InitialState = {
  loading: false,
  user: {
    name: 'Kevin Systrom',
  },
  error: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
})

export default userSlice.reducer