import React from 'react';
import './App.scss';
import CreateComment from './components/CreateComment/CreateComment';
import DiscussionsList from './components/Discussionslist/DiscussionsList';
import { useAppSelector } from './store/hooks';

function App() {
  const discussions = useAppSelector((state) => state.discussions.discussions);
  return (
    <div className="App">
      <div className="App__head">
        <CreateComment placeholder={'start a discussion'} />
      </div>
      <DiscussionsList discussions={discussions} />
    </div>
  );
}

export default App;
