import React, { FC } from 'react';
import './DiscussionsList.scss';
import { IDiscussion } from '../../types';
import DiscussionItem from '../DiscussionItem/DiscussionItem';

interface DiscussionsListProps {
  discussions: IDiscussion[];
}

const DiscussionsList: FC<DiscussionsListProps> = ({ discussions }) => {
  return (
    <div className="DiscussionsList">
      {discussions.map((discussion) => (
        <DiscussionItem discussion={discussion} key={discussion.id} />
      ))}
    </div>
  );
};

export default DiscussionsList;
