import React, { FC, useState } from 'react';
import { IDiscussion } from '../../types';
import Comment from '../Comment/Comment';
import CreateComment from '../CreateComment/CreateComment';
import './DiscussionItem.scss';

interface DiscussionItemProps {
  discussion: IDiscussion;
}

const DiscussionItem: FC<DiscussionItemProps> = ({ discussion }) => {
  const [showReply, setShowReply] = useState(false);
  return (
    <div className={'DiscussionItem ' + (discussion.replies.length ? 'hasChild' : '')}>
      <Comment data={discussion} key={discussion.id} showHideReply={() => setShowReply(!showReply)} />
      {discussion.replies.map((reply) => (
        <Comment data={reply} parentId={discussion.id} key={reply.id} />
      ))}
      {showReply && <CreateComment placeholder={'reply'} parentId={discussion.id} />}
    </div>
  );
};

export default DiscussionItem;
