import React, { FC } from 'react';
import './Avatar.scss';

interface AvatarProps {
  url?: string;
  name: string;
}

const Avatar: FC<AvatarProps> = ({ url, name }) => {
  return url ? (
    <img className="Avatar" src={url} alt={name} />
  ) : (
    <div className="Avatar">{name.match(/\b(\w)/g)?.join('')}</div>
  );
};

export default Avatar;
