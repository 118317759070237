import React, { FC } from 'react';
import './Button.scss';

interface ButtonProps {
  theme?: 'themePrimary' | 'themeLight' | 'themeLink';
  rounded?: 'roundedFull';
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FC<ButtonProps> = ({
  theme = 'themePrimary',
  rounded,
  type = 'button',
  disabled,
  onClick,
  children,
  ...props
}) => (
  <button onClick={onClick} className={`Button ${theme} ${rounded}`} type={type} disabled={disabled} {...props}>
    {children}
  </button>
);

export default Button;
